



















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import md5 from 'md5'
import { Collection, View } from '@/models'
import PaginatedList from '@/components/PaginatedList.vue'

import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'

@Component({
  components: {
    ComponentCreateDialog,
    PaginatedList
  }
})
export default class AdminDesign extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  searchQuery = ''
  sortBy = 'name'
  createModalOpen = false

  items!: Partial<Collection>[]

  transformResult(view: Partial<View>) {
    return {
      ...view,
      title: view.name,
      subtitle: view.path,
      href: {
        name: 'adminDesignEdit',
        params: {
          environmentId: this.environmentId,
          viewId: view._id
        }
      }
    }
  }

  async handleCreate(view: View) {
    // this.$apollo.queries.views.refetch()
    await this.$router.push({
      name: 'adminDesignEdit',
      params: {
        environmentId: this.environmentId,
        viewId: view._id
      }
    })
  }

  toggleCreateModal(value: any) {
    this.createModalOpen =
      value != null && typeof value === 'boolean'
        ? value
        : !this.createModalOpen
  }
}
